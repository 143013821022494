import * as React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { breakpoints } from '../styles'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Paragraph from '../components/Paragraph'
import TextWrapper from '../components/TextWrapper'

const ContentWrapper = styled.div`
  display: flex;
  @media screen and (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }
`

const ImageWrapper = styled.div`
  overflow: hidden;
  height: 140px;
  width: 140px;
  margin: 0 18px 18px;
  flex-shrink: 0;
  @media screen and (max-width: ${breakpoints.tablet}) {
    align-self: center;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title='Home' />
    <ContentWrapper>
      <ImageWrapper>
        <StaticImage
          src='../images/About.png'
          alt='Picture of Yannis Xenakis'
          width={140}
          height={140}
          layout='fixed'
          loading='eager'
          placeholder='tracedSVG'
        />
      </ImageWrapper>
      <TextWrapper>
        <Paragraph>
          Yannis XENAKIS served as an official of the European Commission in
          Brussels for thirty five years. He lives in Brussels with his family.
        </Paragraph>
        <Paragraph>
          He studied political sciences and economics in Greece, France, the UK
          and Belgium.
        </Paragraph>
        <Paragraph>
          In the European Commission, he worked in economic analysis, the
          Economic and Monetary Union, the euro banknotes and coins, protection
          of EU financial interests and customs anti-fraud policy.
        </Paragraph>
        <Paragraph>
          Retired in 2014, he has been teaching European Economy in universities
          in Germany and France.
        </Paragraph>
        <Paragraph>Native Greek, he is fluent in English and French.</Paragraph>
      </TextWrapper>
    </ContentWrapper>
  </Layout>
)

export default IndexPage
